import { useMutation } from '@apollo/client'
import { produce } from 'immer'

import { CreateInvoiceLineItemDocument } from '@/graphql/purchasing/generated/createInvoiceLineItem.generated'
import { GetAvailableProductsQuery, ProductEdge } from '@/graphql/purchasing/generated/purchasing_graphql'
import { GET_AVAILABLE_PRODUCTS } from '@/graphql/purchasing/operations/queries/getAvailableProducts'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { ProductType } from '@/modules/shared/types'

interface UseCreateInvoiceLineItemProps {
  invoiceId?: number
  line?: ProductType
}

export function useCreateInvoiceLineItem(args?: UseCreateInvoiceLineItemProps) {
  const { invoiceId, line } = args || {}
  const mutation = useMutation(CreateInvoiceLineItemDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    refetchQueries: ['GetInvoice'],
    update: (cache) => {
      if (line) {
        const queryToCache = {
          query: GET_AVAILABLE_PRODUCTS,
          variables: {
            invoiceId,
            requisitionId: 0,
            catalogueId: 0,
            sort: {
              property: 'created_at',
              direction: 'DESC',
            },
          },
        }
        const availableProductsCache = cache.readQuery(queryToCache) as GetAvailableProductsQuery
        const newAvailableProductsCache = produce(availableProductsCache, (draft) => {
          const { edges } = draft.currentPurchaser?.availableProducts || {}
          if (draft.currentPurchaser && draft.currentPurchaser.availableProducts) {
            draft.currentPurchaser.availableProducts.edges = edges?.map((e) => {
              if (e?.node?.id === line?.product?.id) {
                if (e && e.node) {
                  e.node.invoiced = true
                }
              }
              return e
            }) as ProductEdge[]
          }
        })
        cache.writeQuery({ ...queryToCache, data: newAvailableProductsCache })
      }
    },
  })

  return mutation
}
