import { ApolloClient, ApolloProvider, InMemoryCache, NormalizedCacheObject, ServerError, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ApolloPersistorProvider } from '@/apollo/ApolloPersistorProvider'
import createOperationNameLink from '@/apollo/CreateOperationNameLink'
import { apolloRelayStylePagination } from '@/apollo/utils/apolloRelayStylePagination'
import { dataPersistenceMapper } from '@/apollo/utils/dataPersistenceMapper'
import { Spinner } from '@/modules/requisitions/components/spinner/Spinner'
import { ACCESS_GRAPHQL_API } from '@/modules/shared/constants'
import { useAlert } from '@/modules/shared/hooks/useAlert'

type ApolloProviderWrapperProps = PropsWithChildren

function ApolloProviderWrapper(props: ApolloProviderWrapperProps) {
  const { children } = props
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject>>()
  const [apolloPersistor, setApolloPersistor] = useState<CachePersistor<NormalizedCacheObject>>()

  const { t } = useTranslation()
  const { alertDialog, genericNotAuthorizedAlert } = useAlert()

  useEffect(() => {
    if (apolloClient) {
      const handleClearCache = () => {
        apolloClient.clearStore() // or apolloClient.resetStore() if you want to re-execute active queries
      }

      window.addEventListener('clearApolloCache', handleClearCache)

      return () => {
        window.removeEventListener('clearApolloCache', handleClearCache)
      }
    }
  }, [apolloClient])

  useEffect(() => {
    async function init() {
      const errorLink = onError((error) => {
        const networkError = error.networkError as ServerError
        const isNotAuthorized = (error?.graphQLErrors || []).some(
          (e) => e?.extensions?.code === 401 && e?.extensions?.status === 'Unauthorized'
        )

        // Any request met with a 401 response
        if (networkError?.statusCode === 401) {
          // These operations shouldn't raise an alert dialog
          if (
            error.operation.operationName === 'GetSessionUser' ||
            error.operation.operationName === 'GetCurrentPurchaser' ||
            error.operation.operationName === 'SignOut'
          ) {
            return
          }
          return alertDialog({
            type: 'error',
            title: t('alert.unauthenicated.title', 'Please Sign In Again'),
            message: t('alert.unauthenicated.message', 'Your session has expired, please sign in again.'),
            buttonText: t('general.signIn', 'Sign In'),
            onButtonClick() {
              window.location.reload()
            },
          })
        }

        if (networkError?.statusCode >= 500) {
          // this is to help us debug these issues when they appear in Sentry, without triggering more Sentry errors
          console.log(error)

          // These operations shouldn't raise an alert dialog
          if (error.operation.operationName === 'GetDashboardStats') return

          return alertDialog({
            type: 'error',
            title: t('general.internalServerError', 'Internal Server Error'),
            message: t(
              'alert.internalServerError.message',
              'Sorry but there was an internal server error. Please try again later.'
            ),
            buttonText: t('general.okay', 'Okay'),
          })
        }

        if (isNotAuthorized || networkError?.statusCode === 403) {
          return genericNotAuthorizedAlert()
        }

        if (networkError) {
          const operationName = error.operation.operationName
          const operationErrorMessage = error.networkError?.message
          const statusCode = (error.networkError as ServerError)?.statusCode

          networkError.message = `${operationName} (Status code ${statusCode}): "${operationErrorMessage}"`
        }

        // this is to help us debug these issues when they appear in Sentry, without triggering more Sentry errors
        console.log(error)
        // All other errors
        // `handleErrorLocally` will decide whether to show a global error or handle and show the error locally
        if (!networkError && !error.operation.getContext().handleErrorLocally) alertDialog({ type: 'error' })
      })

      const operationNameLink = createOperationNameLink(ACCESS_GRAPHQL_API, import.meta.env.VITE_APP_VERCEL_ENV || '')

      const uploadLink = createUploadLink({
        uri: ACCESS_GRAPHQL_API,
        credentials: 'include',
      })

      const apolloCache = new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              categories: relayStylePagination(['filter']),
              organisations: relayStylePagination(),
              suppliers: relayStylePagination(),
              products: relayStylePagination(),
              productImports: relayStylePagination(),
              financeExportFormats: relayStylePagination(),
              accountsPayableSystems: relayStylePagination(),
              financeExportExecutions: relayStylePagination(),
              barcodes: relayStylePagination(),
              stockItems: relayStylePagination(),
              stockLocations: apolloRelayStylePagination(),
              transfers: relayStylePagination(),
              users: relayStylePagination(),
              tradeRelationships: relayStylePagination(),
              pipes: relayStylePagination(),
              stockTakes: relayStylePagination(),
              recipes: relayStylePagination(),
              menus: relayStylePagination(),
              organisationImports: relayStylePagination(),
              reports: relayStylePagination(),
              workflows: relayStylePagination(),
              procurementProducts: relayStylePagination(),
              ingredients: relayStylePagination(),
              aiScanningJobs: relayStylePagination(),
              pricedCatalogues: relayStylePagination(),
              tradeRelationshipPricedCatalogues: apolloRelayStylePagination(),
              aiPrompts: apolloRelayStylePagination(),
              allPricedCatalogues: relayStylePagination(),
              allTradeRelationshipPricedCatalogues: apolloRelayStylePagination(),
              pointOfSaleItems: relayStylePagination(),
              pointOfSaleOutlets: relayStylePagination(),
              billingGroups: relayStylePagination(),
              pointOfSaleBatches: relayStylePagination(),
              pendingInvoices: relayStylePagination(),
              processDocuments: relayStylePagination(),
              purchaseOrders: relayStylePagination(),
              allInvoices: relayStylePagination(),
              userImports: relayStylePagination(),
              deliveryAddresses: relayStylePagination(),
              orders: relayStylePagination(),
              dashboardStats: {
                // dashboardStats doesnt have an id so we will store data as a flat structure without normalizing it
                merge: (_existing, incoming) => {
                  return incoming
                },
              },
            },
          },
          WorkflowRank: {
            fields: {
              workflowRankMemberships: relayStylePagination(),
            },
          },
          Catalogue: {
            fields: {
              cataloguedProducts: relayStylePagination(),
            },
          },
          Category: {
            fields: {
              categories: relayStylePagination(['filter']),
            },
          },
          Holder: {
            fields: {
              stockLocations: relayStylePagination(),
              stockTakes: relayStylePagination(),
            },
          },
          Preference: {
            keyFields: ['key'],
          },
          Purchaser: {
            fields: {
              accounts: relayStylePagination(),
              allAccounts: relayStylePagination(),
              availableCatalogues: relayStylePagination(),
              availableProducts: relayStylePagination(),
              catalogues: relayStylePagination(),
              creditNotes: relayStylePagination(),
              deliveryAddresses: relayStylePagination(),
              invoices: relayStylePagination(),
              pricedCatalogues: relayStylePagination(),
              purchaseOrders: relayStylePagination(),
              receivingDocuments: relayStylePagination(),
              requisitions: relayStylePagination(),
              requisitionsAwaitingMyApproval: relayStylePagination(),
              supplierRelationships: relayStylePagination(),
              supplierRelationshipsWithCatalogue: relayStylePagination(),
              users: relayStylePagination(),
            },
          },

          Requisition: {
            fields: {
              possibleRequisitionLines: relayStylePagination(),
            },
          },
          TransferItem: {
            keyFields: ['stockItem', ['id']],
          },
          RequisitionLine: {
            keyFields: (object) => {
              if (object.cataloguedProductId) return ['cataloguedProductId']
              if (object.id) return ['id']
              return ['uuid']
            },
          },
          StockTake: {
            fields: {
              stockCounts: relayStylePagination(),
              stocktakeImports: relayStylePagination(),
              stocktakeExports: relayStylePagination(),
            },
          },
          Supplier: {
            fields: {
              availableProducts: relayStylePagination(),
              publicCataloguedProducts: relayStylePagination(),
              purchasers: relayStylePagination(),
              users: relayStylePagination(),
            },
          },
          PricedCatalogue: {
            fields: {
              pricedCataloguedProducts: relayStylePagination(),
              cataloguedProducts: relayStylePagination(),
              catalogueImports: relayStylePagination(),
              catalogueExports: relayStylePagination(),
              tradeRelationshipPricedCatalogues: relayStylePagination(),
            },
          },
          PurchaseOrder: {
            fields: {
              lines: relayStylePagination(),
              purchaseOrderLineItems: relayStylePagination(),
            },
          },
          Invoice: {
            fields: {
              invoiceLineItems: relayStylePagination(),
            },
          },
          ReceivingDocument: {
            fields: {
              receivingDocumentLineItems: relayStylePagination(),
            },
          },
          Report: {
            fields: {
              executions: relayStylePagination(),
              templates: relayStylePagination(),
            },
          },
          User: {
            fields: {
              organisations: relayStylePagination(),
            },
          },
          CreditNote: {
            fields: {
              creditNoteLines: relayStylePagination(),
            },
          },
          AccountsPayableSystem: {
            fields: {
              suppliers: relayStylePagination(),
              purchasers: relayStylePagination(),
              vendors: relayStylePagination(),
              accounts: relayStylePagination(),
              taxCodes: relayStylePagination(),
            },
          },
          Location: {
            fields: {
              stockLevels: relayStylePagination(),
              valueAdjustments: relayStylePagination(),
              transfers: relayStylePagination(),
            },
          },
          Transfer: {
            fields: {
              transferItems: relayStylePagination(),
            },
          },
          StockItem: {
            fields: {
              substituteProducts: relayStylePagination(),
            },
          },
          Product: {
            fields: {
              substituteProducts: relayStylePagination(),
            },
          },
          Recipe: {
            fields: {
              lineItems: relayStylePagination(),
              menus: relayStylePagination(),
            },
          },
          Menu: {
            fields: {
              recipes: relayStylePagination(),
            },
          },
          AiPrompt: {
            fields: {
              organisations: relayStylePagination(),
            },
          },
          Workflow: {
            fields: {
              assignments: relayStylePagination(),
              executions: relayStylePagination(),
            },
          },
        },
      })

      // Apollo Persistor
      setApolloPersistor(
        new CachePersistor({
          cache: apolloCache,
          storage: new LocalStorageWrapper(window.localStorage),
          debug: import.meta.env.VITE_APP_VERCEL_ENV === 'production',
          trigger: 'write',
          persistenceMapper: async (data) => {
            // Control what portions of the cache are persisted by passing the __typename into the array
            // Ex: dataPersistenceMapper(data, [...typenames])
            return dataPersistenceMapper(data, ['StockCount', 'StockTake', 'StockLocation'])
          },
        })
      )

      // Apollo Client
      setApolloClient(
        new ApolloClient({
          link: from([errorLink, operationNameLink, uploadLink]),
          cache: apolloCache,
          connectToDevTools: import.meta.env.MODE === 'development',
        })
      )
    }

    init().catch(console.error)
  }, [])

  if (!apolloClient || !apolloPersistor) return <Spinner className="mt-12 h-28 md:h-32" data-testid="spinner" />
  return (
    <ApolloProvider client={apolloClient}>
      <ApolloPersistorProvider persistor={apolloPersistor}>{children}</ApolloPersistorProvider>
    </ApolloProvider>
  )
}

export default ApolloProviderWrapper
