import { matchPath, useLocation } from 'react-router-dom'

export default function useShouldHideLayout() {
  const location = useLocation()
  const requisitionOutsideLayout = [
    '/requisitions/create',
    '/requisitions/:requisitionId/submit',
    '/requisitions/:requisitionId/decline',
    '/requisitions/:requisitionId/manage-purchase-orders',
    '/requisitions/:requisitionId/approve',
    '/requisitions/:requisitionId/shop',
    '/requisitions/:requisitionId/add-product',
    '/requisitions/:requisitionId/shop/advanced',
    '/requisitions/:requisitionId/shop/advanced/settings',
    '/requisitions/:requisitionId/shop/advanced/comments',
    '/requisitions/:requisitionId/shop/advanced/attachments',
    '/requisitions/:requisitionId/shop/summary',
    '/requisitions/:requisitionId/shop/settings',
    '/requisitions/:requisitionId/shop/comments',
    '/requisitions/:requisitionId/shop/attachments',
    '/requisitions/:requisitionId/approve',
    '/requisitions/:requisitionId/success',
  ]

  const buyListOutsideLayout = ['/buy-lists/create', '/buy-lists/:buyListId/add-product', '/buy-lists/builder']

  const catalogsOutsideLayout = ['/catalogs/create', '/catalogs/:pricedCatalogueId/add-product']

  const receivingNotesOutsideLayout = [
    '/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/add-product',
    '/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/legacy/draft',
    '/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/legacy/draft/summary',
    '/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/legacy/draft/settings',
    '/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/legacy/draft/audit-log',
    '/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/received-success',
    '/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/draft',
    '/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/draft/summary',
    '/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/draft/settings',
    '/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/draft/audit-log',
  ]

  const inventoryOutsideLayout = [
    '/inventory/stock-locations/create',
    '/inventory/stock-items/:stockItemId/add-substitute-product',
    '/inventory/transfers/create',
    '/inventory/stocktakes/start',
  ]

  const recipesOutsideLayout = [
    '/recipes/create',
    '/recipes/:recipeId/card',
    '/recipes/:recipeId/add-ingredient',
    '/recipes/:recipeId/print',
  ]

  const menusOutsideLayout = ['/menus/create']
  const usersOutsideLayout = ['/users/create']
  const creditNotesOutsideLayout = [
    '/credit-notes/create',
    '/credit-notes/:creditNoteId/add-line',
    '/credit-notes/credit-note-creator',
  ]
  const mySuppliersOutsideLayout = ['/my-suppliers/create']

  const invoicesOutsideLayout = [
    '/invoices/create',
    '/invoices/:invoiceId/add-line',
    '/invoices/:invoiceId/power-editor',
    '/invoices/:invoiceId/power-editor/summary',
    '/invoices/:invoiceId/power-editor/flags',
    '/invoices/:invoiceId/power-editor/analysis',
    '/invoices/:invoiceId/power-editor/settings',
    '/invoices/:invoiceId/power-editor/comments',
    '/invoices/:invoiceId/power-editor/purchase-order',
    '/invoices/:invoiceId/power-editor/receiving-notes',
    '/invoices/:invoiceId/power-editor/audit-log',
    '/invoices/:invoiceId/power-editor/attachments',
    '/invoices/export-center/create',
    '/invoices/invoice-creator',
    '/invoices/ai-inbox/create',
    '/invoices/export-center/:exportId/view',
    '/invoices/export-center/:exportId/invoices',
    '/invoices/export-center/:exportId/credit-notes',
  ]

  const pointOfSaleOutsideLayout = [
    '/point-of-sale/items/create',
    '/point-of-sale/batch-sales/create',
    '/point-of-sale/outlets/create',
  ]

  const reportsOutsideLayout = ['/reports/:reportShortId/create', '/reports/:reportShortId/:reportNumber/view']

  const utilityRoutesOutsideLayout = [
    '/utilities/',
    '/utilities/organisations',
    '/utilities/users',
    '/utilities/catalogs',
    '/utilities/products',
    '/utilities/ap-systems',
    '/utilities/inventory',
    '/utilities/reports',
    '/utilities/public-shop',
    '/utilities/public-shop/orders',
    '/utilities/delete-stock-count-id',
    '/utilities/import-products',
    '/utilities/import-products/create',
    '/utilities/process-products',
    '/utilities/search-products',
    '/utilities/update-product-code',
    '/utilities/product-codes',
    '/utilities/reports/manage',
    '/utilities/reports/create',
    '/utilities/reports/:reportId',
    '/utilities/reports/:reportId/general',
    '/utilities/reports/:reportId/advanced',
    '/utilities/ap-export-formats',
    '/utilities/ap-export-formats/create',
    '/utilities/ap-export-formats/:apExportFormatId',
    '/utilities/ap-export-formats/:apExportFormatId/general',
    '/utilities/ap-export-formats/:apExportFormatId/variants',
    '/utilities/ap-systems/ap-systems',
    '/utilities/ap-systems/create',
    '/utilities/ap-systems/:apSystemId',
    '/utilities/ap-systems/:apSystemId/general',
    '/utilities/ap-systems/:apSystemId/custom-fields',
    '/utilities/ap-systems/:apSystemId/purchasers',
    '/utilities/ap-systems/:apSystemId/suppliers',
    '/utilities/ap-systems/:apSystemId/accounts',
    '/utilities/ap-systems/:apSystemId/trade-relationships',
    '/utilities/ap-systems/:apSystemId/custom-codes',
    '/utilities/organisations/billing-groups',
    '/utilities/organisations/billing-groups/create',
    '/utilities/organisations/purchasers',
    '/utilities/organisations/suppliers',
    '/utilities/organisations/hierarchies',
    '/utilities/organisations/hierarchies/:organisationId',
    '/utilities/organisations/trade-relationships',
    '/utilities/organisations/destinations',
    '/utilities/organisations/trade-relationships/:tradeRelationshipId',
    '/utilities/organisations/trade-relationships/:tradeRelationshipId/general',
    '/utilities/organisations/trade-relationships/:tradeRelationshipId/catalogs',
    '/utilities/organisations/trade-relationships/:tradeRelationshipId/supplier-details',
    '/utilities/organisations/trade-relationships/:tradeRelationshipId/purchaser-details',
    '/utilities/organisations/trade-relationships/:tradeRelationshipId/destinations',
    '/utilities/organisations/import-organisations',
    '/utilities/organisations/import-organisations/create',
    '/utilities/organisations/:organisationId',
    '/utilities/organisations/:organisationId/general',
    '/utilities/organisations/:organisationId/customize',
    '/utilities/organisations/:organisationId/ap-system',
    '/utilities/organisations/:organisationId/invoicing',
    '/utilities/organisations/:organisationId/inventory',
    '/utilities/organisations/:organisationId/delivery-addresses',
    '/utilities/organisations/:organisationId/ai-prompts',
    '/utilities/organisations/:organisationId/advanced',
    '/utilities/organisations/:organisationId/accounts/update',
    '/utilities/organisations/:organisationId/accounts',
    '/utilities/organisations/:organisationId/public-shop',
    '/utilities/organisations/:organisationId/delivery-fees',
    '/utilities/organisations/purchasers/create',
    '/utilities/organisations/suppliers/create',
    '/utilities/gql-query-builder',
    '/utilities/users/manage',
    '/utilities/users/imports',
    '/utilities/users/imports/create',
    '/utilities/users/create',
    '/utilities/users/:userId',
    '/utilities/users/:userId/general',
    '/utilities/users/:userId/preferences',
    '/utilities/users/:userId/permissions',
    '/utilities/users/:userId/organisations',
    '/utilities/products/:productId',
    '/utilities/products/:productId/details',
    '/utilities/products/:productId/catalogs',
    '/utilities/products/:productId/advanced',
    '/utilities/ai-scanning',
    '/utilities/ai-scanning/emails',
    '/utilities/ai-scanning/emails/create',
    '/utilities/ai-scanning/tasks',
    '/utilities/ai-scanning/tasks/create',
    '/utilities/ai-scanning/prompts',
    '/utilities/ai-scanning/prompts/create',
    '/utilities/ai-scanning/prompts/:aiPromptId',
    '/utilities/ai-scanning/prompts/:aiPromptId/details',
    '/utilities/ai-scanning/prompts/:aiPromptId/organisations',
    '/utilities/ai-scanning/invoice-creator',
    '/utilities/ai-scanning/credit-note-creator',
    '/utilities/trade-relationship-catalogs',
    '/utilities/catalogs/manage',
    '/utilities/catalogs/:pricedCatalogId/products',
    '/utilities/catalogs/:pricedCatalogId/exports',
    '/utilities/catalogs/:pricedCatalogId/imports',
    '/utilities/catalogs/:pricedCatalogId/audit-log',
    '/utilities/products/grooming-tasks',
    '/utilities/products/grooming-tasks/create',
    '/utilities/products/grooming-tasks/:aiScanningTaskId',
    '/utilities/catalogs/:pricedCatalogId/settings',
    '/utilities/catalogs/:pricedCatalogId/assignments',
    '/utilities/categories',
    '/utilities/invoices/:invoiceId',
    '/utilities/invoices/:invoiceId/summary',
    '/utilities/invoices/:invoiceId/flags',
    '/utilities/invoices/:invoiceId/analysis',
    '/utilities/invoices/:invoiceId/settings',
    '/utilities/invoices/:invoiceId/comments',
    '/utilities/invoices/:invoiceId/purchase-order',
    '/utilities/invoices/:invoiceId/receiving-notes',
    '/utilities/invoices/:invoiceId/audit-log',
    '/utilities/invoices/:invoiceId/attachments',
    '/utilities/credit-notes/:creditNoteId',
    '/utilities/credit-notes/:creditNoteId/summary',
    '/utilities/credit-notes/:creditNoteId/flags',
    '/utilities/credit-notes/:creditNoteId/settings',
    '/utilities/credit-notes/:creditNoteId/comments',
    '/utilities/credit-notes/:creditNoteId/attachments',
    '/utilities/credit-notes/:creditNoteId/audit-log',
    '/utilities/ai-scanning/document-processing',
    '/utilities/ai-scanning/document-processing/:id',
    '/utilities/invoices',
    '/utilities/invoices/all-invoices',
  ]

  const apSystemOutsideLayout = [
    '/ap-system/',
    '/ap-system/general',
    '/ap-system/custom-fields',
    '/ap-system/purchasers',
    '/ap-system/suppliers',
    '/ap-system/accounts',
    '/ap-system/trade-relationships',
  ]

  const systemSettingsOutsideLayout = [
    '/settings/approval-workflows/create',
    '/settings/organisations/:organisationId/accounts/update',
  ]

  const publicShopOutsideLayout = ['/shop', '/shop/:organisationId', '/shop/:organisationId/summary']

  const purchaseProductsOutsideLayout = ['/products/:productId/add-substitute-product']

  const legacyStocktakesOutsideLayout = ['/stocktakes/stock-location/:stockLocationId/stocktake/:stocktakeId/offline']

  const noLayoutPaths = [
    ...requisitionOutsideLayout,
    ...buyListOutsideLayout,
    ...catalogsOutsideLayout,
    ...receivingNotesOutsideLayout,
    ...inventoryOutsideLayout,
    ...recipesOutsideLayout,
    ...menusOutsideLayout,
    ...usersOutsideLayout,
    ...invoicesOutsideLayout,
    ...creditNotesOutsideLayout,
    ...reportsOutsideLayout,
    ...utilityRoutesOutsideLayout,
    ...apSystemOutsideLayout,
    ...publicShopOutsideLayout,
    ...purchaseProductsOutsideLayout,
    ...mySuppliersOutsideLayout,
    ...legacyStocktakesOutsideLayout,
    ...systemSettingsOutsideLayout,
    ...pointOfSaleOutsideLayout,
  ]

  const shouldHideLayout = noLayoutPaths.some((path) => matchPath(path, location.pathname))

  return {
    noLayoutPaths,
    shouldHideLayout,
  }
}
